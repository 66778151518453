export default {
  init() {
    // JavaScript to be fired on the Case studies page
    $(function() {
      // Load More button for Case Studies index
      const caseStudiesList = $('#case-studies-list');

      if(caseStudiesList[0]) {
        let currentCSPage = 1;

        $('#case-studies-load-more').on('click', function() {
          currentCSPage++;

          $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php', // need to revert to this when pushing to liv
            dataType: 'json',
            data: {
              action: 'case_studies_load_more',
              page: currentCSPage,
            },
            success: function (res) {
              if(currentCSPage >= res.max) {
                $('#case-studies-load-more').hide();
              }

              $('#case-studies-list').append(res.html);
            },
          });
        });
      }

      $('.case-studies-index__category').on('click', function() {
        $('.case-studies-index__category').removeClass('active');
        $(this).addClass('active');

        $.ajax({
          type: 'POST',
          url: '/wp-admin/admin-ajax.php',
          dataType: 'html',
          data: {
            action: 'filter_case_studies',
            category: $(this).data('category'),
            type: $(this).data('type'),
          },
          success: function(res) {
            $('#case-studies-list').html(res);

            let resultsLength = $(res).filter('.case-studies-index__list-item').length;

            if (resultsLength <= 1) {
              $('#case-studies-load-more').hide();
            } else {
              $('#case-studies-load-more').show();
            }
          },
        })
      });
    });
  },
};
