import Swiper from 'swiper';

export default {
  init() {
    // JavaScript to be fired on all pages
    $(function() {
      // Nav menu sub menu dropdown
      var parentMenuItem = $("#menu-primary-navigation .menu-item");

      parentMenuItem.each(function(){
        if ($(this).children(".sub-menu").length) {
          $(this).addClass("has-sub-menu");

          $(this).on('mouseenter',function(){
            $(this).addClass("active");
          }).on('mouseleave',function () {
            $(this).removeClass("active");
          });
        }
      });

      $('.burger-menu').on('click', function() {
        $(this).toggleClass('open');

        $('.mobile-header__items').slideToggle();
        $('.mobile-header__items').toggleClass('open');
      });

      $('.mobile-header .menu-item-has-children').on('click', function() {
        $(this).toggleClass('active');

        $('.mobile-header .menu-item-has-children .sub-menu').slideToggle();
        $('.mobile-header .menu-item-has-children .sub-menu').toggleClass('active');
      });
    });

    window.addEventListener("load", function() {
      const testimonials = new Swiper('.testimonials__slider-outer', {
        direction: 'horizontal',
        loop: true,
        watchOverflow: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });

      const gallery = new Swiper('.gallery-slider__swiper', {
        slidesPerView: 1,
        loop: true,
        direction: 'horizontal',
        centeredSlides: true,
        breakpoints: {
          900: {
            slidesPerView: 2.75,
            spaceBetween: 40,
          },
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          clickable: true,
        },
      });

      // only work when on screen sizes 900px down
      var init = false;
      var swiper;
      function swiperSlides() {
        if (window.innerWidth <= 900) {
          if (!init) {
            init = true;

            const latestArticles = new Swiper('.latest-articles__list-swiper', {
              slidesPerView: 1.25,
              loop: false,
              direction: 'horizontal',
              freemode: true,
              spaceBetween: 20,
              scrollbar: {
                el: '.swiper-scrollbar',
                clickable: true,
              },
            });

            const servicesList = new Swiper('.services-main__swiper', {
              slidesPerView: 1.25,
              loop: false,
              direction: 'horizontal',
              freemode: true,
              spaceBetween: 20,
              scrollbar: {
                el: '.swiper-scrollbar',
                clickable: true,
              },
            });
          }
        } else if (init) {
          swiper.destroy();
          init = false;
        }
      }
      swiperSlides();
      window.addEventListener("resize", swiperSlides);
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
