import Swiper from 'swiper';

export default {
  init() {
    // JavaScript to be fired on the home page

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    window.addEventListener("load", function() {
      const homeBannerSlider = new Swiper('.home__slider-banner', {
        direction: 'horizontal',
        centeredSlides: true,
        loop: true,
        preloadImages: true,
        speed: 1500,
        slidesPerView: "auto",
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          clickable: true,
        },
      });

      const homeTestimonial = new Swiper('.home-testimonials__slider-outer', {
        direction: 'horizontal',
        loop: true,
        watchOverflow: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    });
  },
};
