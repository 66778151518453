export default {
  init() {
    // JavaScript to be fired on the Articles page

    $(function() {
      // Load More button for Article index
      const articleList = $('#articles-list');

      if(articleList[0]) {
        let currentArticlePage = 1;


        $('#article-load-more').on('click', function() {
          currentArticlePage++;

          $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: {
              action: 'article_load_more',
              page: currentArticlePage,
            },
            success: function (res) {
              if(currentArticlePage >= res.max) {
                $('#article-load-more').hide();
              } else {
                $('#article-load-more').show();
              }

              $('#articles-list').append(res.html);
            },
          });
        });
      }

      // Filtering
      $('.articles-index-banner__category').on('click', function() {
        $('.articles-index-banner__category').removeClass('active');
        $(this).addClass('active');

        $.ajax({
          type: 'POST',
          url: '/wp-admin/admin-ajax.php',
          dataType: 'html',
          data: {
            action: 'filter_articles',
            category: $(this).data('category'),
          },
          success: function(res) {
            $('#articles-list').html(res);

            let resultsLength = $(res).filter('.article-card').length;

            console.log(resultsLength);

            if (resultsLength <= 3) {
              $('#article-load-more').hide();
            } else {
              $('#article-load-more').show();
            }
          },
        })
      });
    });
  },
}
