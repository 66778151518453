import Swiper from 'swiper';

export default {
  init() {
    // JavaScript to be fired on the services page

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    window.addEventListener("load", function() {
      var init = false;
      var swiper;
      function swiperCard() {
        if (window.innerWidth <= 900) {
          if (!init) {
            init = true;

            const servicesList = new Swiper('.services-main__swiper', {
              slidesPerView: 1.25,
              loop: false,
              direction: 'horizontal',
              freemode: true,
              spaceBetween: 20,
              scrollbar: {
                el: '.swiper-scrollbar',
                clickable: true,
              },
            });
          }
        } else if (init) {
          swiper.destroy();
          init = false;
        }
      }
      swiperCard();
      window.addEventListener("resize", swiperCard);
    });
  },
};
